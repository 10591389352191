import type { ProductLine, Totals, PurchaseInput } from './payload.types';
import type { AppState } from 'behavior';
import type { Epic } from 'behavior/types';
import { EMPTY, merge } from 'rxjs';
import {
  ignoreElements,
  pluck,
  switchMap,
  filter,
  delayWhen,
  first,
  share,
  tap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  AnalyticsAction,
  ANALYTICS_PRODUCT_CLICKED,
  ANALYTICS_PRODUCT_DETAILS_VIEWED,
  ANALYTICS_PRODUCT_LIST_VIEWED,
  ANALYTICS_PRODUCTS_ADDED_TO_BASKET,
  ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET,
  ANALYTICS_CHECKOUT,
  ANALYTICS_CHECKOUT_OPTION,
  ANALYTICS_PURCHASE,
} from './actions';
import { pushToDataLayer } from './dataLayer';
import {
  createProductClickPayload,
  createProductDetailsViewPayload,
  createProductListViewPayload,
  createProductsAddToBasketPayload,
  createProductsRemoveFromBasketPayload,
  createCheckoutPayload,
  createCheckoutOptionPayload,
  createPurchasePayload,
} from './payload';
import { orderDetailsQuery } from './queries';
import { skipIfPreview } from 'behavior/preview';

type Order = {
  lines: {
    itemLines: Array<ProductLine>;
  };
  totals: Totals;
}

type DocumentsResponse = {
  documents: {
    orders: {
      byId: Order;
    };
  };
}

const analyticsEpic: Epic<AnalyticsAction> = (action$, state$, { api, scope }) => {
  if (scope === 'SERVER')
    return EMPTY;

  const dataLayerReady$ = state$.pipe(
    first(({ analytics }) => analytics!.isDataLayerReady!),
  );

  const delayedAction$ = action$.pipe(
    skipIfPreview(state$),
    filter(_ => state$.value.analytics.isTrackingEnabled),
    ofType(
      ANALYTICS_PRODUCT_CLICKED,
      ANALYTICS_PRODUCT_DETAILS_VIEWED,
      ANALYTICS_PRODUCT_LIST_VIEWED,
      ANALYTICS_PRODUCTS_ADDED_TO_BASKET,
      ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET,
      ANALYTICS_CHECKOUT,
      ANALYTICS_CHECKOUT_OPTION,
      ANALYTICS_PURCHASE,
    ),
    delayWhen(_ => dataLayerReady$),
    share(),
  );

  return merge(
    delayedAction$.pipe(
      tap(action => {
        switch (action.type) {
          case ANALYTICS_PRODUCT_CLICKED:
            pushToDataLayer(state$.value, action.payload, createProductClickPayload);
            break;
          case ANALYTICS_PRODUCT_DETAILS_VIEWED:
            pushToDataLayer(state$.value, action.payload, createProductDetailsViewPayload);
            break;
          case ANALYTICS_PRODUCT_LIST_VIEWED:
            pushToDataLayer(state$.value, action.payload, createProductListViewPayload);
            break;
          case ANALYTICS_PRODUCTS_ADDED_TO_BASKET:
            pushToDataLayer(state$.value, action.payload, createProductsAddToBasketPayload);
            break;
          case ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET:
            pushToDataLayer(state$.value, action.payload, createProductsRemoveFromBasketPayload);
            break;
          case ANALYTICS_CHECKOUT:
            pushToDataLayer(state$.value, action.payload, createCheckoutPayload);
            break;
          case ANALYTICS_CHECKOUT_OPTION:
            pushToDataLayer(state$.value, action.payload, createCheckoutOptionPayload);
            break;

          default:
            break;
        }
      }),
      ignoreElements(),
    ),
    delayedAction$.pipe(
      ofType(ANALYTICS_PURCHASE),
      pluck('payload', 'transaction'),
      switchMap(({ documentId: id }) => api.graphApi<DocumentsResponse>(orderDetailsQuery, { id }).pipe(
        pluck('documents', 'orders', 'byId'),
        tap(order => {
          const data = toPurchaseInput(order, state$.value, id);
          pushToDataLayer(state$.value, data, createPurchasePayload);
        }),
        ignoreElements(),
      )),
    ),
  );
};

const toPurchaseInput = ({
  lines: { itemLines },
  totals,
}: Order, {
  user: { pricesInclTax },
  settings: { shopName },
}: AppState,
  documentId: string,
): PurchaseInput => ({
  itemLines,
  totals,
  pricesInclTax,
  shopName,
  documentId,
});

export default analyticsEpic;
