import styles from './Basket.module.scss';
import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import { useSelector } from 'react-redux';

const BasketTitle = () => {
  const [CXmlPunchoutAbility] = useHasAbilities(AbilityTo.CXmlPunchoutAbility);
  var basket = useSelector(state => state.basket);
  var isOCIUser = basket?.model?.isOCIUser

  var textKey = 'ShoppingBasket_Header';
  if (CXmlPunchoutAbility && !isOCIUser) {
    textKey = 'CXmlPunchout_ShoppingBasket_Header';
  }

  if (CXmlPunchoutAbility && isOCIUser) {
    textKey = 'OCIPunchout_ShoppingBasket_Header';
  }


  return (
    <h1 className={styles.title}>
      <SimpleText
        textKey={textKey}
        placeholder={<Placeholder className={styles.titlePlaceholder} />}
      />
    </h1>
  );
};

export default memo(BasketTitle);
